import React from 'react';
import Layout from '../../components/shared/Layout';
import SEO from '../../components/shared/SEO';
import AdditionalInformation
    from "../../components/shared/additionalInformation/additionalInformation";
import Footer from "../../components/shared/Footer/Footer";
import {getRoutes} from "../../components/shared/routes";
import Breadcrumbs from "../../components/shared/Breadcrumbs/Breadcrumbs";
import BackToTechnologyButton from "../../components/shared/ButtonBackTo/BackToTechnologyButton";
import DcodeMarkerAutomated2 from "../../components/sections/DcodeMarkerAutomated2/DcodeMarkerAutomated2";

const DmaSAAutomated = () => {
    const routes = getRoutes();
    const parentTitle = routes.Technology.pageTitle;
    const title = routes.TechnologyDMASAAutomated.pageTitle;

    return (
        <Layout>
            <SEO title={title}/>

            <Breadcrumbs
                title={title}
                parentTitles={[parentTitle]}
                parentRoutes={[routes.Technology]}
            />

            <DcodeMarkerAutomated2/>

            <BackToTechnologyButton/>

            <AdditionalInformation/>

            <Footer/>
        </Layout>
    );
};

export default DmaSAAutomated;
